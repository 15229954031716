import React, { Component } from "react";
import { Router, Switch } from "react-router-dom";

import "./css/bootstrap-overrides.scss";
import "react-notifications/lib/notifications.css";
import "bootstrap/dist/js/bootstrap";

import types from "./tutorials/types.json";
import { NotificationContainer, NotificationManager } from "react-notifications";
import AuthFather from "@twzzr/authfather";
import { analytics, messaging } from "./setup/firebase.config";
import { compose } from "redux";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { AdminKey, AuthLevel, TwzzrLoader, getEnvironment } from "./setup/shared";
import { firestoreConnect } from "react-redux-firebase";
import TwzzrBoundary from "./twzzrBoundary";
import { rollbar } from ".";

export const history = createBrowserHistory();

const PrivacyPolicyPage = React.lazy(() => import("./public/privacyPolicyPage"));
const TermsConditionsPage = React.lazy(() => import("./public/termsConditionsPage"));
const AdminConsole = React.lazy(() => import("./admin/consolePage"));
const MainDashboard = React.lazy(() => import("./mainDashboardPage"));
const PlaceOrder = React.lazy(() => import("./orders/placeOrderPage"));
const SignIn = React.lazy(() => import("./authentication/signInPage"));
const SignUp = React.lazy(() => import("./authentication/signUpPage"));
const UpdateInventory = React.lazy(() => import("./technicians/updateInventoryPage"));
const PhoneLogin = React.lazy(() => import("./authentication/phoneLoginPage"));
const RecoverPasswordPage = React.lazy(() => import("./authentication/recoverPasswordPage"));
const NotFoundPage = React.lazy(() => import("./components/notFoundPage"));
const TechPlaceOrderPage = React.lazy(() => import("./technicians/techPlaceOrderPage"));
const TechMgmt = React.lazy(() => import("./admin/techniciansManagementPage"));
const Profile = React.lazy(() => import("./users/profilePage"));
const AboutPage = React.lazy(() => import("./public/aboutPage"));
const HomePage = React.lazy(() => import("./public/homePage"));
const AdminImsPage = React.lazy(() => import("./ims/adminImsPage"));
const OrderDetailsPage = React.lazy(() => import("./orders/orderDetailsPage"));
const ServicesPage = React.lazy(() => import("./services/servicesPage"));
const TutorialsPage2 = React.lazy(() => import("./tutorials/allTutorials"));

const TutorialPage = React.lazy(() => import("./tutorials/tutorialPage"));
const Navbar = React.lazy(() => import("./components/navbar"));
const Footer = React.lazy(() => import("./components/footer"));

const ProductsList = React.lazy(() => import("./products/productsListPage"));
const TutorialAuthPage = React.lazy(() => import("./products/tutorialAuthPage"));
const MakersDeskPage = React.lazy(() => import("./public/makersDeskPage"));

export const createNotification = (type, title, body, delay) => {
  switch (type) {
    case "info":
      NotificationManager.info(title);
      break;
    case "success":
      NotificationManager.success(body, title, delay || 1000);
      break;
    case "warning":
      NotificationManager.warning(title, body, delay || 1000);
      break;
    case "error":
      NotificationManager.error(title, body, delay || 1000);
      break;
    default:
      NotificationManager.warning("invalid notification type", body, delay || 1000);
      break;
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageTitle: null,
      messageBody: null,
      messageType: null,
      messageID: null,
      errorType: null,
      errorMessage: null,
    };
  }

  async componentDidMount() {
    if (messaging) {
      messaging
        .requestPermission()
        .then(async () => {
          // eslint-disable-next-line
          const token = await messaging.getToken();
        })
        .catch(() => console.log("Messaging Permission denied."));
      navigator.serviceWorker.addEventListener("message", (message) => {
        const messageBody = message["data"]["firebase-messaging-msg-data"]["notification"]["body"];
        const messageTitle =
          message["data"]["firebase-messaging-msg-data"]["notification"]["title"];

        const notification = new Notification(messageTitle, {
          body: messageBody,
        });
        notification.onclick = function () {
          window.open("https://twzzr.in");
        };
      });
    }
  }

  setError = (type, message) => {
    this.setState({
      errorType: type,
      errorMessage: message,
    });
  };

  render() {
    const COMMON = {
      HEADERS: ["|", "Twizzr"],
      WRAP: (children) => (
        <>
          <React.Suspense fallback={<TwzzrLoader />}>
            <Navbar
              auth={this.props.auth}
              profile={this.props.profile}
              technicians={this.props.technicians && Object.keys(this.props.technicians)}
            />
          </React.Suspense>
          <React.Suspense fallback={<TwzzrLoader />}>
            <main id="main" style={{ paddingTop: "64px", width: "100%" }}>
              <TwzzrBoundary auth={this.props.auth}>{children}</TwzzrBoundary>
            </main>
          </React.Suspense>
          <React.Suspense>
            <Footer isAdmin={this.props.auth && this.props.auth.uid === AdminKey} />
          </React.Suspense>
        </>
      ),
      PROPS: {
        auth: this.props.auth,
        technicians: this.props.technicians,
        profile: this.props.profile,
      },
      AUTH_CHECK: (roles) => {
        if (!this.props.auth.uid && roles.includes(AuthLevel.NONE)) {
          return AuthLevel.NONE;
        } else if (this.props.auth.uid) {
          if (this.props.auth.uid === AdminKey) {
            return AuthLevel.ADMIN;
          } else if (
            this.props.auth.uid &&
            this.props.auth.uid !== AdminKey &&
            this.props.technicians &&
            Object.keys(this.props.technicians).includes(this.props.auth.uid)
          ) {
            return AuthLevel.TECHNICIAN;
          }
          return AuthLevel.CUSTOMER;
        }
        return null;
      },
    };
    return (
      <Router history={history}>
        <NotificationContainer />
        <React.Suspense fallback={<TwzzrLoader />}>
          <Switch>
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<HomePage />}
              path="/"
              redirectLink="/dashboard"
              roles={AuthLevel.NONE}
              title={["Twizzr"]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<AboutPage />}
              path="/aboutus"
              redirectLink="/dashboard"
              roles={AuthLevel.NONE}
              title={["About Us", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<TechMgmt />}
              path="/admin/technicians"
              redirectLink="/"
              roles={AuthLevel.ADMIN}
              title={["Technicians Management", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<AdminConsole {...COMMON.PROPS} />}
              path="/admin/console"
              redirectLink="/"
              roles={AuthLevel.ADMIN}
              title={["Admin Console", ...COMMON.HEADERS]}
            />

            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<AdminImsPage {...COMMON.PROPS} />}
              path="/admin/ims"
              redirectLink="/"
              roles={AuthLevel.ADMIN}
              title={["Admin IMS", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              component={<RecoverPasswordPage {...COMMON.PROPS} />}
              path="/auth/recover"
              redirectLink="/dashboard"
              roles={AuthLevel.NONE}
              title={["Recover Password", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              component={<SignIn {...COMMON.PROPS} />}
              path="/auth/signin"
              redirectLink="/dashboard"
              roles={AuthLevel.NONE}
              title={["Sign In", ...COMMON.HEADERS]}
            />

            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              component={<PhoneLogin {...COMMON.PROPS} />}
              path="/auth/signin/phone"
              redirectLink="/dashboard"
              roles={AuthLevel.NONE}
              title={["Sign In with Mobile", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              component={<SignUp {...COMMON.PROPS} />}
              path="/auth/signup"
              redirectLink="/dashboard"
              roles={AuthLevel.NONE}
              title={["Join Us!", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<MainDashboard {...COMMON.PROPS} lastQuote={this.props.lastQuote} />}
              path="/dashboard"
              redirectLink="/"
              roles={[AuthLevel.ADMIN, AuthLevel.CUSTOMER, AuthLevel.TECHNICIAN]}
              title={["Dashboard", ...COMMON.HEADERS]}
            />

            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<UpdateInventory {...COMMON.PROPS} />}
              path="/ims/update"
              redirectLink="/"
              roles={AuthLevel.TECHNICIAN}
              title={["Add Ticket to IMS", ...COMMON.HEADERS]}
            />

            <AuthFather
              blanket={COMMON.WRAP}
              component={<PrivacyPolicyPage />}
              path="/legal/privacy"
              title={["Privacy Policy", ...COMMON.HEADERS]}
            />
            <AuthFather
              blanket={COMMON.WRAP}
              component={<TermsConditionsPage />}
              path="/legal/tnc"
              title={["Terms and Conditions", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<OrderDetailsPage {...COMMON.PROPS} />}
              path="/orders/:orderid"
              redirectLink="/"
              roles={[AuthLevel.ADMIN, AuthLevel.CUSTOMER, AuthLevel.TECHNICIAN]}
              title={["Order Details", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={
                <PlaceOrder lastQuote={this.props.lastQuote} onBehalfOf={false} {...COMMON.PROPS} />
              }
              path="/order/create"
              redirectLink="/"
              roles={AuthLevel.CUSTOMER}
              title={["Place an Order", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<Profile auth={this.props.auth} profile={this.props.profile} />}
              path="/profile"
              redirectLink="/"
              roles={[AuthLevel.TECHNICIAN, AuthLevel.CUSTOMER]}
              title={["Profile", ...COMMON.HEADERS]}
            />

            {Object.keys(types).map((tutorialItem, index) => (
              <AuthFather
                authCheck={COMMON.AUTH_CHECK}
                blanket={COMMON.WRAP}
                component={<TutorialPage type={tutorialItem} />}
                key={index}
                path={`/tutorial${types[tutorialItem].linkTo}`}
                redirectLink="/dashboard"
                roles={AuthLevel.NONE}
                title={[`Tutorials for ${types[tutorialItem].name}`, ...COMMON.HEADERS]}
              />
            ))}
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<TutorialsPage2 />}
              path="/tutorials"
              redirectLink="/dashboard"
              roles={AuthLevel.NONE}
              title={["Tutorials", ...COMMON.HEADERS]}
            />

            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={
                <TechPlaceOrderPage onBehalfOf {...COMMON.PROPS} lastQuote={this.props.lastQuote} />
              }
              path="/technicians/order/create"
              redirectLink="/"
              roles={AuthLevel.TECHNICIAN}
              title={["Create OBO Order", ...COMMON.HEADERS]}
            />

            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              blanket={COMMON.WRAP}
              component={<ProductsList {...COMMON.PROPS} />}
              path="/products"
              roles={AuthLevel.NONE}
              title={["Explore Products!", ...COMMON.HEADERS]}
            />
            <AuthFather
              authCheck={COMMON.AUTH_CHECK}
              component={<TutorialAuthPage {...COMMON.PROPS} />}
              path="/auth/tutorials"
              roles={AuthLevel.NONE}
              title={["Tutorial Authentication!", ...COMMON.HEADERS]}
            />
            <AuthFather
              blanket={COMMON.WRAP}
              component={<ServicesPage />}
              path="/services"
              title={["Services", ...COMMON.HEADERS]}
            />
            <AuthFather
              blanket={COMMON.WRAP}
              component={<MakersDeskPage />}
              path="/makersdesk"
              title={["Makers Desk", ...COMMON.HEADERS]}
            />
            <AuthFather
              component={<NotFoundPage />}
              exact={false}
              path="*"
              title={["404: Page Not Found", ...COMMON.HEADERS]}
            />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  const firebaseAuth = state.firebase.auth;
  let firebaseProfile = null;
  let lastQuoteId = null;
  const userInfo = { environment: getEnvironment };
  // let y=state.firstore.data.technicians;
  if (!firebaseAuth.isEmpty) {
    firebaseProfile = state.firebase.profile;
    userInfo["id"] = firebaseAuth.uid;
    userInfo["email"] = firebaseAuth.email;
    userInfo["isTechnician"] =
      state.firestore.ordered.technicians &&
      Object.keys(state.firestore.ordered.technicians).includes(firebaseAuth.uid);
    lastQuoteId =
      state.firestore &&
      state.firestore.data &&
      state.firestore.data.static &&
      state.firestore.data.static.lastQuote;
  }
  rollbar && rollbar.configure({ payload: { person: userInfo } });
  analytics && analytics.setUserProperties(userInfo);

  return {
    auth: firebaseAuth,
    lastQuote: lastQuoteId,
    profile: firebaseProfile,
    technicians: state.firestore.data.technicians,
  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (props.auth.uid) return ["static", "technicians"];
    return ["technicians"];
  })
)(App);
