import React from "react";
import { AdminKey, isProduction } from "./setup/shared";
import { history } from "./app";
import { rollbar } from ".";
class TwzzrBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { error };
  }

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    // Send error to Rollbar.
    if (isProduction) {
      rollbar.error(error);
    }
  }

  goBackHandler = () => {
    history.goBack();
  };

  reloadHandler = () => {
    history.go(0);
  };

  render() {
    const isAdmin = this.props.auth && this.props.auth.uid === AdminKey;
    if (this.state.error) {
      return (
        <div className="container">
          <div className="alert alert-danger">
            <h4 className="alert-heading">Sorry, we've faced an error!</h4>

            <p>
              {isAdmin ? (
                <>
                  {this.state.error.message}
                  <br />
                  {this.state.error.stack}
                </>
              ) : (
                <>
                  Its already been reported, but We'd love if you could describe what brought you to
                  this error.
                  <br />
                </>
              )}
              <div aria-label="Basic example" className="btn-group btn-group-sm mt-5" role="group">
                {!isAdmin ? (
                  <button className="btn btn-primary" onClick={this.dialogHandler} type="button">
                    Describe Error
                  </button>
                ) : null}
                <button
                  className="btn btn-outline-primary"
                  onClick={this.reloadHandler}
                  type="button"
                >
                  Reload Page
                </button>
                <button className="btn btn-secondary" onClick={this.goBackHandler} type="button">
                  Previous Page
                </button>
              </div>
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default TwzzrBoundary;
