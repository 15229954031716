import authReducer from "./authReducer";
import orderReducer from "./orderReducer";
import profileReducer from "./profileReducer";
import { combineReducers } from "redux";

import { firestoreReducer } from "redux-firestore";

import { firebaseReducer } from "react-redux-firebase";
import { analytics } from "../../setup/firebase.config";
import adminReducer from "./adminReducer";
import imsReducer from "./imsReducer";

export const registerEvent = ({ name, body }) => {
  console.log("GoogleAnalytics", name, body);
  analytics && analytics.logEvent(name, body);
};

const rootReducer = combineReducers({
  admin: adminReducer,
  auth: authReducer,
  ims: imsReducer,
  profile: profileReducer,
  order: orderReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});
export default rootReducer;
