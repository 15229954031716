import { createNotification } from "../../app";
import { registerEvent } from "./rootReducer";
import { reduxTypes } from "@twzzr/support";

const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case reduxTypes.admin.ancexpenses.create.success:
      registerEvent({ name: action.type, body: action });
      createNotification("success", "Ancillary expense successfully created!");
      return state;
    case reduxTypes.admin.ancexpenses.create.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.ancexpenses.delete.success:
      createNotification("warning", "Ancillary expense successfully deleted!");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.admin.ancexpenses.delete.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.ancexpenses.update.success:
      createNotification("success", "Ancillary expense successfully updated!");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.admin.ancexpenses.update.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.offers.create.success:
      createNotification("success", "Successfully created new offer!");
      registerEvent({ name: action.type, body: action });
      return state;
    // return {...state, error:"Error in offffff"}
    case reduxTypes.admin.offers.create.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.offers.delete.success:
      registerEvent({ name: action.type, body: action });
      createNotification("success", "Successfully deleted offer!");
      return state;
    case reduxTypes.admin.offers.delete.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.offers.update.success:
      registerEvent({ name: action.type, body: action });
      createNotification("success", "Successfully updated offer!");
      return state;
    case reduxTypes.admin.offers.update.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.pincodes.create.success:
      registerEvent({ name: action.type, body: action });
      createNotification("success", "Successfully created pincode!");
      return state;
    case reduxTypes.admin.pincodes.create.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.pincodes.delete.success:
      registerEvent({ name: action.type, body: action });
      createNotification("success", "Successfully deleted pincode!");
      return state;
    case reduxTypes.admin.pincodes.delete.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.pincodes.update.success:
      registerEvent({ name: action.type, body: action });
      createNotification("success", "Successfully updated pincode!");
      return state;
    case reduxTypes.admin.pincodes.update.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.technicians.allot.success:
      createNotification(
        "success",
        "Successful Allotment!",
        `${action.payload.id} alloted to ${action.payload.technician}`
      );
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.admin.technicians.allot.fail:
      return { ...state, error: action.payload };

    case reduxTypes.admin.technicians.assign.success:
      createNotification("success", "Successfully assigned order!");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.admin.technicians.assign.fail:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
export default adminReducer;
