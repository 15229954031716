import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider, useSelector } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./reduxStore/reducers/rootReducer";
import { getFirestore, createFirestoreInstance } from "redux-firestore";
import { ReactReduxFirebaseProvider, getFirebase, isLoaded } from "react-redux-firebase";
import firebaseConfig from "./setup/firebase.config";
// import App from "./App";

import { isProduction, TwzzrLoader } from "./setup/shared";
import Rollbar from "rollbar";

const App = React.lazy(() => import("./app"));

export const rollbar = new Rollbar({
  enabled: isProduction,
  accessToken: "68d852e56360462ca077b007a0a12746",
  captureUncaught: true,
  projectVersion: process.env.REACT_APP_VERSION,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV === "production" ? "production" : "development",
    client: {
      javascript: {
        source_map_enabled: true,
        projectVersion: process.env.REACT_APP_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
  captureIp: "anonymize",
});

if (isProduction) {
  console.log("App Version", process.env.REACT_APP_VERSION);
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch((err) => {
      console.log("Service worker registration failed, error:", err);
    });
}
//-------STORE-------------//
export const store = createStore(
  rootReducer,
  applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore }))
);

export const rrfProps = {
  firebase: firebaseConfig,
  config: {
    useFirestoreForProfile: true,
    userProfile: "users",
    attachAuthIsReady: true,
  },
  createFirestoreInstance,
  dispatch: store.dispatch,
};

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <TwzzrLoader />;
  return children;
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <React.Suspense fallback={<TwzzrLoader />}>
          {/* <React.StrictMode> */}
          <App />
          {/* </React.StrictMode> */}
        </React.Suspense>
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
