import { createNotification } from "../../app";
import { registerEvent } from "./rootReducer";
import { reduxTypes } from "@twzzr/support";

const orderReducer = (state = {}, action) => {
  switch (action.type) {
    case reduxTypes.order.create.success:
      registerEvent({ name: action.type, body: action });
      createNotification("success", "Order Placed");
      return state;
    case reduxTypes.order.create.fail:
      return {
        ...state,
        error: action.payload,
      };
    case reduxTypes.order.create.emailFail:
      return {
        ...state,
        message:
          "You're order has been created, but we couldn't send an email to you. We've raised an error with our team, either ways. Thank you!",
      };

    case reduxTypes.order.update.success:
      createNotification("success", "Order succesfully updated!");
      registerEvent({ name: action.type, body: action });
      return { ...state };
    case reduxTypes.order.update.fail:
      return { ...state, error: action.payload };

    case reduxTypes.order.delete.success:
      createNotification("warning", "Order successfully deleted!");
      registerEvent({ name: action.type, body: action });
      return { ...state };
    case reduxTypes.order.delete.fail:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
export default orderReducer;
