// import { Redirect } from "react-router-dom";

import { createNotification } from "../../app";
import { registerEvent } from "./rootReducer";
import { reduxTypes } from "@twzzr/support";

const initState = {
  authError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case reduxTypes.auth.signIn.success:
      registerEvent({ name: action.type, body: action });
      createNotification("success", "Welcome!", "Successfull Signin");
      return state;
    case reduxTypes.auth.signIn.fail:
      return {
        ...state,
        error: action.payload,
      };

    case reduxTypes.auth.signOut.success:
      registerEvent({ name: action.type, body: action });
      createNotification("warning", "Successfull Signout");
      return state;
    case reduxTypes.auth.signOut.fail:
      return {
        ...state,
        error: action.payload,
      };

    case reduxTypes.auth.signUp.success:
      createNotification("success", "Successfull Signup!", "Thank you for joining us!");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.auth.signUp.fail:
      return {
        error: action.error,
      };

    case reduxTypes.auth.otp.send.success:
      createNotification(
        "success",
        "OTP Successfully Sent",
        "Please check your mobile device for the OTP."
      );
      registerEvent({ name: action.type, body: action });
      return {
        ...state,
        otpSent: true,
      };
    case reduxTypes.auth.otp.send.fail:
      return {
        ...state,
        error: action.payload,
      };

    case reduxTypes.auth.otp.verify.success:
      createNotification("success", "OTP Verification Successfull", "Mobile number linked!");
      registerEvent({ name: action.type, body: action });
      return state;
    // case reduxTypes.auth.otp.verify authActionTypes.otp.verify.fail:
    //   return {
    //     error: action.payload,
    //   };
    case reduxTypes.auth.otp.verify.wrongOtp:
      return {
        ...state,
        error: action.payload,
      };

    case reduxTypes.auth.withProvider.success:
      createNotification("success", "Successful!");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.auth.withProvider.fail:
      return {
        ...state,
        error: action.payload,
      };
    case reduxTypes.auth.withProvider.relogin:
      createNotification("error", "Re-login", "Please relogin to perform this task.");
      registerEvent({ name: action.type, body: action });
      return state;

    default:
      return state;
  }
};
export default authReducer;
