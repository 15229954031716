// ----------x--Be Careful While Handling This File || No Editing Unless Necessary---x------------------//

//------------------Import these for integrating our data to Firebase---------------//
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/performance";
import "firebase/messaging";
import "firebase/storage";
import "firebase/functions";
import "firebase/remote-config";
import { isProduction } from "./shared";

// Our  Web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6dUWtyrz1cQd1sMZqRCLLFWci1IcW4wQ",
  authDomain: "tweezer-4f5cd.firebaseapp.com",
  databaseURL: "https://tweezer-4f5cd.firebaseio.com",
  projectId: "tweezer-4f5cd",
  storageBucket: "tweezer-4f5cd.appspot.com",
  messagingSenderId: "783592476616",
  appId: "1:783592476616:web:79a5f36e33283c6b573afd",
  measurementId: "G-BEQ87MGLQJ",
};

// Initialize Firebase
export const initializeFirebaseApp = firebase.initializeApp(firebaseConfig);
let firebaseAnalytics;

if (isProduction) {
  firebase.performance();
  firebaseAnalytics = firebase.analytics();
}

export const analytics = firebaseAnalytics;
export const storage = firebase.storage();
export let messaging = null;
export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 5000;

remoteConfig
  .ensureInitialized()
  .then(() => {
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        console.log("Firebase Remote Config Activated");
      })
      .catch((err) => {
        console.error("FBRC", err);
      });
  })
  .catch((err1) => {
    console.error("FBRC", err1);
  });

if (firebase.messaging.isSupported()) {
  messaging = initializeFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    "BM3Ptsp2a9zUN6poSM73EgYi0YH5hkrfax4EwabiblrPCl1ZgfW_PxNBkrlkkQmwVsRbuswWJj90GTiRDhBMCQU"
  );
}
// firebase.firestore().settings({});

export default firebase;
