import { registerEvent } from "./rootReducer";
import { reduxTypes } from "@twzzr/support";
import { createNotification } from "../../app";

const initState = {};

const imsReducer = (state = initState, action) => {
  switch (action.type) {
    case reduxTypes.ims.admin.create.success:
      createNotification("success", "New IMS entry made");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.ims.admin.create.fail:
      return {
        ...state,
        error: action.payload,
      };

    case reduxTypes.ims.admin.update.success:
      createNotification("success", "Inventory item successfully updated");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.ims.admin.update.fail:
      return {
        ...state,
        error: action.payload,
      };

    case reduxTypes.ims.create.success:
      createNotification("success", "Inventory ticket successfully made");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.ims.create.fail:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default imsReducer;
