import { registerEvent } from "./rootReducer";
import { reduxTypes } from "@twzzr/support";
import { createNotification } from "../../app";

const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case reduxTypes.profile.edit.success:
      createNotification("success", "Profile successfully edited!");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.profile.edit.fail:
      return {
        ...state,
        error: action.payload,
      };

    case reduxTypes.profile.link.success:
      createNotification("success", "Profile successfully linked!");
      registerEvent({ name: action.type, body: action });
      return state;
    case reduxTypes.profile.link.fail:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default profileReducer;
