import React from "react";

// eslint-disable-next-line
export const isProduction = process.env.NODE_ENV === "production";

// eslint-disable-next-line
export const AdminKey = isProduction
  ? "gVmC69Vb8zgOCZ7vicGE6VgnHZf1"
  : "gVmC69Vb8zgOCZ7vicGE6VgnHZf1";

export const AuthLevel = {
  ADMIN: "ADMIN",
  CUSTOMER: "CUSTOMER",
  NONE: "NONE",
  TECHNICIAN: "TECHNICIAN",
};

/**
 * Depending on isProduction, takes value "prod"/"dev"
 * @listens isProduction
 */
export const getEnvironment = isProduction ? "prod" : "dev";

/**
 * Simple loader for lazyloading in Twizzr Repair
 */
export const TwzzrLoader = () => <div className="loader" />;

/**
 * Demo email IDs, in case we ever test on production Twizzr. These are useful to mark orders as ignore-able.
 *
 */
export const DemoEmailIDs = [
  "aalokaryan10@gmail.com",
  "nirmalhk7@gmail.com",
  "viveshCodes@gmail.com",
  "support@twzzr.in",
];
